import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hi, thanks for uploading your track and contributing to Trailguide! The following
points should help to keep the high quality of the Trailguide "Trail Biking" map.
It will make it easier to find good trails, and to keep the map clean and readable:`}</p>
    <br />
    <h4>{`1) Singletracks only`}</h4>
    <p>{`A singletrack is a narrow path, which often has a width of less than 1,5m. No
fire, gravel or tarmac roads (you can upload these to the other categories).
Please cut your track to the parts of singletrack in the trail editor. Check our `}<a href="https://trailguide.no/features/uploadactivities" target="wiki">{`website`}</a>{`
to see how to edit and upload your tracks with Trailguide:`}</p>
    <br />
    <h4>{`2) Connect trails`}</h4>
    <p>{`If the track contains short parts of gravel road - for example to connect two
singletracks - it is up to you if you split the track into two or not. One descend,
flat trail, or loop, should be as continuous as possible without creating too
many individual trails. The connections on non singletrack should be very short
though and not exceed 10-20% of the singletrack.`}</p>
    <br />
    <h4>{`3) Loops/Tours`}</h4>
    <p>{`Upload loops only if all parts are on singletracks (access, climb, descend,
flat parts). If not, cut the track to the parts on singletrack. You still can
show the whole tour by uploading it as “hidden” and link the URL in the trail
description of the singletrack. Check out how to upload tours on our `}<a href="https://trailguide.no/pro/touraccess" target="wiki">{`website`}</a>{`.`}</p>
    <br />
    <h4>{`4) Redundancy`}</h4>
    <p>{`Before you upload a trail, check if it’s already on the map. If it overlaps an
existing trail for a short section (10%) it’s ok. Cut the trail if it overlaps
more. Also if your track goes up and down or forth and back on the same way,
cut out one way.`}</p>
    <br />
    <h4>{`5) GPS quality`}</h4>
    <p>{`Please upload only tracks that have a good track quality. Make sure the track has
enough recorded points to make it look smooth. Not edgy with a few angled straight sections.
That could have happened due to an interrupted GPS signal, or because you drew
the track manually. In areas with a poor reception, the Signal can drift and is
off your actual position.`}</p>
    <br />
    <h4>{`6) Description`}</h4>
    <p>{`Describe the character of the trail in a view words or sentences. Imagine someone
doesn’t know the region and the trail, but should get a brief impression of what
she can expect. Don’t describe the directions, that’s what the GPX track is for.
See tips for a good description `}<a href="https://trailguide.no/pro/description" target="wiki">{`here`}</a>{`.`}</p>
    <br />
    <h4>{`7) Pictures`}</h4>
    <p>{`“A picture says more than a thousand words”. Best way to show what you can expect
of the trail. Try to catch the character of the trail, its typical surface and obstacles.`}</p>
    <br />
    <p>{`Please help us to ensure the high quality of Trailguide. That will make it easy
for everyone to use and enjoy Trailguide.`}</p>
    <br />
Thanks for contributing to Trailguide and making it a great tool to find and share
the best MTB singletracks!
    <br />
    <br />
    <p>{`Cheers, `}<br />{`
your Trailguide Team`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      